import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import App from "./App";
import "./styles/App.css";
import InternetStream from "./long/content/InternetStream";
import KademliaIntro from "./long/content/KademliaIntro";
import MinSteal from "./long/content/3_minStolen,";
import Nothing from "./Nothing";
import Preview from "./Preview";
import BacteriophagesOne from "./long/content/Bacteriophages_1";

const ERoutes = () => {
  return (
    <div>
      <Routes>
        <Route exact path="/internet_stream" element={<InternetStream />} />
        <Route exact path="/kademlia_intro" element={<KademliaIntro />} />
        <Route exact path="/minified_steal" element={<MinSteal />} />
        <Route exact path="/home" element={<App />} />
        <Route exact path="/" element={<Navigate replace to="/home" />} />
        <Route path="/preview" element={<Preview />} />
        <Route path="*" element={<Nothing />} />
        <Route path="/bacteriophages_intro" element={<BacteriophagesOne />} />
      </Routes>
    </div>
  );
};

export default ERoutes;
