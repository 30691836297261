import "./styles/App.css";
import KademliaIntro from "./short/content/KademliaIntro";
import BacteriophagesOne from "./short/content/BacteriophagesOne";

function RightWing() {
  return (
    <>
      <div className="blog-post">
        <KademliaIntro />
        <BacteriophagesOne />
      </div>
    </>
  );
}

export default RightWing;
