import BabyPic from "../../../assets/baby_steps.png";
import Template from "../";

const header = `
｡^‿^｡
`;

const description = `
｡^‿^｡
`;

const location = "/internet_stream";

const date = "August, 2022";

const InternetFlow = () => {
  return Template(BabyPic, header, description, location, date, false, [
    "｡^‿^｡",
  ]);
};

export default InternetFlow;
