import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import history from "./history";
import { HashRouter as Router } from "react-router-dom";
import ERoutes from "./components/Routes";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router history={history}>
      <ERoutes />
    </Router>
  </React.StrictMode>
);
