import phage from "../../../assets/bacteriophage.png";
import Template from "..";

const header = `Bacteriophages 1`;

const description = ` [Potentially] start of series on biological warfare and 
defense`;

const location = "/bacteriophages_intro";

const date = `November, 2022`;

const BacteriophagesOne = () => {
  return Template(phage, header, description, location, date, false, ["science", "phages"]);
};

export default BacteriophagesOne;
