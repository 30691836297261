import Template from "..";
import BabyPic from "../../../assets/baby_steps.png";

export const header = `

# ｡^‿^｡ 
`;

export const content = `                          ｡^‿^｡  `;

const InternetStream = () => {
  return Template(BabyPic, header, content);
};

export default InternetStream;
