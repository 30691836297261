import Template from "..";
import picture from "../../../assets/bacteriophage.png";
import Phage from "../../../assets/bacteriophage1.png";

export const header = `# [Potentially] More Reliable Antibiotic`;

export const content = `
# Intro
---
Development, production, acquisition, transfer, stockpiling and use of biological
and toxin weapons are prohibited by Biological Weapons Convention (BWC). It opened
for signature on 10 April 1972 and entered into force on 26 March 1975 
[[link](https://www.un.org/disarmament/biological-weapons/)]. The BWC supplements the
1925 Geneva Protocol, which had prohibited only the use of biological weapons.

Geneva protocol in its time and BWC were both ignored [[link](https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1326439/)].

It's a necessity to develop a good suitable opposition to these weapons.
Bacteriophages are potentially good for treating any bacterial disease.

## Discovery

Bacteria have always been an important part of any biological warfare.

Bacteria that are known for good military practice include Bacteria Bacillus 
anthracis, Brucella spp., Burkholderia mallei, Burkholderia pseudomallei,
Chlamydophila psittaci, Coxiella burnetii, Francisella tularensis,  Shigella spp.,
Vibrio cholerae, and Yersinia pestis  but are not limited to 
[[wiki](https://en.wikipedia.org/wiki/Biological_warfare#Anti-personnel)]. 

Wanted this or not humans had to face some of these threats during war times [[link](https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1326439/)].

First discover of "something that doing antibacterial action against cholera" 
has been reported couple years before World War I in Indian rivers by 
[[Ernest Hanbury Hankin](https://en.wikipedia.org/wiki/Ernest_Hanbury_Hankin)]. Tho
it didn't go any further. 

There was [[another british](https://en.wikipedia.org/wiki/Frederick_Twort)] 
bacteriologist who rediscovered presence of bacteriophages in 1915. He made several
claims about bacteriophages focused on "they could possible face bacterial threat".
Unfortunately he was turned down with lack of funding because of war.
Also because of antibiotics.

Independently from this guy another dude from France has discovered this thing
durign WWI [[sci-hub](https://sci-hub.se/10.1097/ta.0000000000001809)].
But for him the conditions of the war turned out to
be favorable. [[Félix d'Hérelle](https://en.wikipedia.org/wiki/F%C3%A9lix_d%27H%C3%A9relle)]
was French-Canadian microbiologist who was treating soldiers stationed at Maisons-Laffitte
suffering from severe hemorrhagic dysentery. During his free time he liked to play around
with their feces. Why not? In these he noticed a material with the ability to inhibit the
growth of the pathogenic bacteria 
[[link](https://www.tandfonline.com/doi/pdf/10.4161/bact.1.1.14941#:~:text=In%20the%20absence%20of%20dysentery,antidysenteric%20microbe%20is%20an%20obligate)].
The material specifically appeared in samples from
patients during recuperation, suggesting it had a role in the process. He figured
that were viruses and he was the one who called them *bacteriophages*
[[link](https://sci-hub.se/10.1097/ta.0000000000001809)],[[link](https://en.wikipedia.org/wiki/Bacteriophage#History)].
He also was the first guy in the history who successfully used bacteriophages in
human therapy [[sci-hub](https://sci-hub.se/10.1097/ta.0000000000001809)]. He later joined
his friend George Eliava who loved the idea of playing around and 
had established a center for bacteriophage research and therapy in Georgia [[which is still active](https://eptc.ge/)].

USSR and Eastern Europe were lucky enough to find good phages to treat their diseases.
USA and CO weren't that lucky. They got different phages to treat similar diseases
but little did they know phages are highly specific to their hosts and won't
know about the existence of other bacteria, even if they were inside them. It resulted
in American Medical Association publishing strict criticism against phage therapy 
[[link](https://jamanetwork.com/journals/jama/article-abstract/255543)]. This and 
discovery of penicillin has led West to decrease use of phages.

First massive application of bacteriophages was held during WWII. Every soldier
got bacteriophage' mixture against gangrene' and purulent infections' bacteria.
This led to significance decrease in gangrene and amputations among the military
contingents [[link](https://pubmed.ncbi.nlm.nih.gov/22748807/)], [[link](https://pubmed.ncbi.nlm.nih.gov/27090515/)].
Also in Stalingrad, which was occupied by German troops, people was infected with cholera.
It was treated by taking phages orally and cholera ceased within several weeks 
[[link](https://pubmed.ncbi.nlm.nih.gov/27090515/)].

Research on phages was conducted in USSR and the Eastern Europe. They progressed
quite far so US didn't touch this area until the end of the cold war,
so as not to be behind in the wartime. Even when many papers were published openly,
western countries didn't progress in phage' research simply because they didn't understand
georgian or russian [[wiki](https://en.wikipedia.org/wiki/Bacteriophage#Phage_therapy)].
Later though bacteriophages were approved by the FDA and by other regulatory
authorities worldwide as food additives, and as food decontaminants.

The reasons behind this were pretty simple. Go-to solutions against bacteria involved
antibiotics. Suddenly time of non-resistant to antibiotics bacteria came to an end [[link]](https://www.nejm.org/doi/10.1056/NEJMp1408040),
[[Fleming too was concerned](https://www.nobelprize.org/uploads/2018/06/fleming-lecture.pdf)].
And people had to think of something else.

## Bacteriophages
### What they are
Bacteriophages (aka phages) are viruses that infect bacteria or archaea. 
They use infected organism' cellular mechanisms to reproduce themselves.
As a rule, phages consist of whatever nucleic acid and capsid (aka protein shell)

![phage](${Phage})\
*[[this is how typical phage look like](https://www.researchgate.net/figure/Typical-bacteriophage-structure_fig1_320324824)]*

The majority of studied bacteriophages contained tails. Such phages (with tails)
are called [Caudovirales](https://en.wikipedia.org/wiki/Caudovirales) 
and they can be further divided according to the length of
their tail. Though not all bacteriophages have tails.

Phage' attack on bacterium could be resolved in two different ways. Simple: bacterium
lives or dies. This depends on

### How phages work

Tailed phages bind their receptors (which are located on tail) to the cell surface.
Then they inject their (most of the time [dsDNA](https://en.wiktionary.org/wiki/dsDNA))
into the host' cytoplasm. From here their actions start to differ. 

(I) *bacterium dies in the process*. So called lytic pathway. Here some genes 
has such promotors that bind host' RNA-polimerase to them what leads to transcription
of phage' genome. It creates new capsids and new phages are being born. These phages
then break the cell from the inside and crawl out to the environment.

(II) *bacterium lives*. Aka lysogenic cycle, where phage genome can integrate into
the bacterial genome, protecting the bacterial host from further infections with 
the same bacteriophage. The lysogenic bacteriophage will remain dormant until it 
will be induced, spontaneously or by stress conditions, and will be excised from
the bacterial chromosome, possibly attached to bacterial DNA fragments [[link](https://journals.asm.org/doi/epub/10.1128/AAC.45.3.649-659.2001)].

![lytic-lisogenic](https://www.researchgate.net/publication/334465076/figure/fig1/AS:780912845402115@1563195539996/Bacteriophage-Lifecycle-Lytic-phages-attach-and-infect-a-bacterial-cell-which-results-in.png)\
*[[lytic-lisogenic pathways](https://www.researchgate.net/figure/Bacteriophage-Lifecycle-Lytic-phages-attach-and-infect-a-bacterial-cell-which-results-in_fig1_334465076)]*

It is actually can be known what which pathway will be chosen [[link](https://pubmed.ncbi.nlm.nih.gov/28099413/)].

For therapeutic uses lytic bacteriophages are preferred.

This concludes introduction.

`;

const BacteriophagesOne = () => {
  return Template(picture, header, content);
};

export default BacteriophagesOne;
