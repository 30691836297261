import "../styles/long.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkImages from "remark-images";
import rehypeHighlight from "rehype-highlight";
import React from "react";
import ghLogo from "../../assets/gh.svg";
import Face from "../../assets/LOGO1.png";
import { Link } from "react-router-dom";

import { useEffect, useState } from "react";

const AnimatedTemplate = (image, header, content) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => (index + 1) % content.length);
    }, 250);
    return () => clearInterval(interval);
  });

  return (
    <>
      <div className="container">
        <img className="image-long" src={image} alt="baby_steps pic" />
      </div>
      <div className="content-long">
        <div className="header-long">
          <ReactMarkdown
            children={header}
            remarkPlugins={[remarkGfm, remarkImages]}
          />
        </div>
        <div className="content-content-long">
          <div className="text-long">
            <div className="animated-content-long">
              <ReactMarkdown
                children={content[index]}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeHighlight]}
                components={{
                  img: ({ node, ...props }) => (
                    <img
                      style={{ maxWidth: "100%" }}
                      {...props}
                      alt="depicted graph"
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-footer">
        <div className="img-long">
          <a href="https://pillowguy.me" rel="noreferrer" target="_blank">
            <img src={Face} className="long-pic" alt="milady eyes" />
          </a>
        </div>
        <Link to="/">
          <button className="button-back-long">back</button>
        </Link>
        <div className="img-long">
          <a
            href="https://github.com/poolsar42"
            rel="noreferrer"
            target="_blank"
          >
            <img src={ghLogo} className="long-pic" alt="github logo" />
          </a>
        </div>
      </div>
    </>
  );
};

export default AnimatedTemplate;
