import "../styles/short.css";
import { Link } from "react-router-dom";
import TagTemplate from "./tag";

const Template = (
  image,
  header,
  description,
  location,
  date,
  noborder = false,
  tags = false,
  noborderImage = false
) => {
  return (
    <div className={noborder ? "noborder-container-short" : "container-short"}>
      <Link to={location}>
        <img
          className={noborderImage ? "noborder-image-short" : "image-short"}
          src={image}
          alt="baby_steps pic"
        />
      </Link>

      <div className="content-short">
        <Link to={location}>
          <div className="header">{header}</div>
        </Link>
        <p className="description-short">{description}</p>
        <div className="floating-element">
          {tags && tags.length > 0 && (
            <div className="tags-short">
              {tags.map((tag) => {
                return <TagTemplate key={tag} text={tag} />;
              })}
            </div>
          )}
          <p className="published">{date}</p>
        </div>
      </div>
    </div>
  );
};

export default Template;
