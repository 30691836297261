import "./style.css";
import { Link } from "react-router-dom";
import TagTemplate from "../../tag";

const MinstrealTemplate = (
  image,
  header,
  description,
  location,
  date,
  tags = false
) => {
  return (
    <div className="minsteal-container-short">
      <Link to={location}>
        <img
          className="minsteal-image-short"
          src={image}
          alt="baby_steps pic"
        />
      </Link>
      <div className="minsteal-content-short">
        <Link to={location}>
          <div className="minsteal-header">{header}</div>
        </Link>
        <div className="minsteal-description-short">{description}</div>
        <div className="floating-element">
          {tags && tags.length > 0 && (
            <div className="tags-short">
              {tags.map((tag) => {
                return <TagTemplate key={tag} text={tag} />;
              })}
            </div>
          )}
          <p className="minsteal-published">{date}</p>
        </div>
      </div>
    </div>
  );
};

export default MinstrealTemplate;
