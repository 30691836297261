import "./styles/App.css";
import InternetStream from "./short/content/InternetStream";
import KademliaIntro from "./short/content/KademliaIntro";
import Minsteal from "./short/content/Minstreal/3_minStolen,";
import BacteriophagesOne from "./short/content/BacteriophagesOne";

function MobileWing() {
  return (
    <>
      <div className="blog-post">
        <InternetStream />
        <KademliaIntro />
        <Minsteal />
        <BacteriophagesOne />
      </div>
    </>
  );
}

export default MobileWing;
