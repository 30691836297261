import "./styles/App.css";
import InternetStream from "./short/content/InternetStream";
import Minsteal from "./short/content/Minstreal/3_minStolen,";

function LeftWing() {
  return (
    <>
      <div className="blog-post">
        <InternetStream />
        <Minsteal />
      </div>
    </>
  );
}

export default LeftWing;
