import minStolen from "../../../../assets/minStolen.png";
import MinstrealTemplate from "./MinstrealTemplate";

const header = `
`;

const description = ``;

const location = "/minified_steal";

const date = "October, 2022";

const Minsteal = () => {
  return MinstrealTemplate(
    minStolen,
    header,
    description,
    location,
    date,
    [`???????`]
  );
};

export default Minsteal;
